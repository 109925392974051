import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    styled,
    Button,
    Icons,
    FullSearchResultContainer,
    FullSearchResultCard,
    MapContext,
    PositionOverlay,
    Coordinates,
} from '@keypro/2nd-xp';
import { Maybe, SearchResult } from '@generated';
import { AdvancedSearch, FilterObject } from './AdvancedSearch';
import {
    getModelIcon,
    IconModel,
    ModelName,
    modelNames,
    objectTypes,
} from '@components/utils';
import { t } from 'i18next';
import { useLeftMenu, useRightMenu } from '@stores';
import { InfoObject } from '@components/InfoObject';
import {
    getTranslatedTitle,
    getTranslationKey,
    getTypeByModel,
} from '@form-configs';

interface FullSearchProps {
    searchResults?: SearchResult[];
    searchTerm?: string;
    isRecentObjects?: boolean;
}

export const FullSearch = ({
    searchResults = [],
    searchTerm = '',
    isRecentObjects = false,
}: FullSearchProps) => {
    const [selectedObjectType, setSelectedObjectType] = useState<string>('');
    const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
    const [isUsingAdvancedSearch, setIsUsingAdvancedSearch] = useState(false);
    const [selectedAdvancedFilters, setSelectedAdvancedFilters] =
        useState<FilterObject>({});
    const [modelAdvancedSearch, setModelAdvancedSearch] = useState<string>('');
    const [dataAdvancedSearch, setDataAdvancedSearch] = useState<
        SearchResult[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [highlightPositions, setHighlightPositions] = useState<
        { id: string; position: number[] }[]
    >([]);
    const ref = useRef<HTMLDivElement>(null);
    const refOverlay = useRef<HTMLDivElement>(null);
    const advancedSearchRef = useRef<{
        handleSubmit: (e: React.FormEvent, newFilters?: string[]) => void;
    }>(null);

    const { setMenuContent: setLeftMenuContent, isMenuOpen } = useLeftMenu();
    const { setMenuContent: setRightMenuContent } = useRightMenu();
    const mapController = useContext(MapContext)!;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setLeftMenuContent('FullSearch', <></>);
                setIsHighlighted(false);
                setHighlightPositions([]);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () =>
            document.removeEventListener('mousedown', handleClickOutside);
    }, [isMenuOpen, setLeftMenuContent]);

    useEffect(() => {
        if (isUsingAdvancedSearch && modelAdvancedSearch) {
            setSelectedObjectType(modelAdvancedSearch);
        }
    }, [isUsingAdvancedSearch, modelAdvancedSearch]);

    const groupByModelName = useMemo(() => {
        return searchResults.reduce(
            (acc, item) => {
                if (item.model_name) {
                    const isArea = item.model_name
                        .toLowerCase()
                        .includes('area');
                    const key = isArea ? 'area' : item.model_name;

                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(item);
                }
                return acc;
            },
            {} as { [key: string]: SearchResult[] },
        );
    }, [searchResults]);

    const showAdvancedSearch = () =>
        setIsAdvancedSearchOpen(!isAdvancedSearchOpen);

    const toggleSelectedObjectType = (objectTypeValue: string) => {
        setSelectedObjectType((prevType) =>
            prevType === objectTypeValue ? '' : objectTypeValue,
        );
        setIsUsingAdvancedSearch(false);
        setSelectedAdvancedFilters({});
    };

    const locateCenterObject = (location: Maybe<string> | undefined) => {
        if (!location) return;

        const locationParts = location.split(';');
        const geom = mapController.wktToGeometry(
            locationParts[locationParts.length - 1],
        );

        if (geom) {
            const extent = geom.getExtent();
            const center = [
                (extent[0] + extent[2]) / 2,
                (extent[1] + extent[3]) / 2,
            ];
            return center;
        }
    };

    const handleHighlight = (list: SearchResult[]) => {
        // Highlight all objects in the list
        const newPositions = list
            .map((item) => {
                return {
                    id: item.id,
                    position: locateCenterObject(item.location),
                };
            })
            .filter((item) => item.position !== undefined);
        setHighlightPositions(
            newPositions as unknown as { id: string; position: number[] }[],
        );
        setIsHighlighted(true);
    };

    const showObject = (model: string, id: number) => {
        setRightMenuContent(
            `QuickSearchObject-${model}-${id}`,
            <InfoObject model={model} id={id.toString()} />,
        );
    };

    const handleClearAdvancedFilter = (
        key: string,
        selectedFilters: FilterObject,
        setFilters: React.Dispatch<React.SetStateAction<FilterObject>>,
        event: React.MouseEvent,
        handleSubmit?: (e: React.FormEvent, groups?: string[]) => void,
    ) => {
        event.stopPropagation();

        const updatedFilters = { ...selectedFilters };
        delete updatedFilters[key];
        setFilters(updatedFilters);

        const updatedGroups = Object.keys(updatedFilters).filter(
            (filterKey) => filterKey !== 'id',
        );

        if (handleSubmit) {
            handleSubmit(event, updatedGroups);
        }
    };

    const renderFilters = () => (
        <>
            <StyledButtons>
                {objectTypes.map(({ value, label }) => (
                    <StyledSearchFiltersType
                        key={value}
                        label={label}
                        $isSelected={selectedObjectType === value}
                        onClick={() => toggleSelectedObjectType(value)}
                    />
                ))}
            </StyledButtons>
            <StyledDivider />
            <StyledSearchFiltersOptions>
                <StyledButtonAddFilter
                    kind="secondary"
                    label={t('addFilter')}
                    data-tooltip={t('openAdvancedSearch')}
                    onClick={showAdvancedSearch}
                    data-testid="open-advanced-search-button"
                />
                <StyledAdvancedSearchFilters>
                    {Object.entries(selectedAdvancedFilters).map(([key]) => {
                        if (key === 'id') return null; // Skip rendering if key is "id"

                        const translationKey = getTranslationKey(
                            getTypeByModel(modelAdvancedSearch),
                            key,
                        );

                        return (
                            <StyledChip
                                key={key}
                                onClick={showAdvancedSearch}
                                data-testid={`advanced-search-filter-${key}`}
                            >
                                {t(translationKey)}
                                <StyledChipClear
                                    onClick={(e) =>
                                        handleClearAdvancedFilter(
                                            key,
                                            selectedAdvancedFilters,
                                            setSelectedAdvancedFilters,
                                            e,
                                            advancedSearchRef.current
                                                ?.handleSubmit,
                                        )
                                    }
                                    data-testid={`clear-advanced-filter-${key}`}
                                >
                                    <Icons.Clear />
                                </StyledChipClear>
                            </StyledChip>
                        );
                    })}
                </StyledAdvancedSearchFilters>
            </StyledSearchFiltersOptions>
        </>
    );

    const renderSearchResults = () => {
        if (isUsingAdvancedSearch && isLoading) {
            return (
                <StyledLoadingAndNoResults>
                    <Icons.Spinner />
                </StyledLoadingAndNoResults>
            );
        }
        if (isUsingAdvancedSearch && dataAdvancedSearch.length === 0) {
            return (
                <StyledLoadingAndNoResults>
                    {t('noSearchResults')}
                </StyledLoadingAndNoResults>
            );
        }
        return isUsingAdvancedSearch && dataAdvancedSearch.length > 0 ? (
            <FullSearchResultContainer
                title={modelNames[selectedObjectType.slice(0, -6) as ModelName]}
                key={selectedObjectType}
                rightBtnLabel={rightBtnLabel}
                data-testid={`full-search-group-${selectedObjectType}`}
                rightBtnAction={() => handleHighlight(dataAdvancedSearch)}
            >
                {dataAdvancedSearch.map((item, index) => (
                    <FullSearchResultCard
                        key={item.id}
                        objectIcon={getModelIcon(item.model_name as IconModel)}
                        name={item.identification as string}
                        type={
                            item.model_name
                                ? getTranslatedTitle(
                                      getTypeByModel(item.model_name),
                                  )
                                : t('unknownObjectType')
                        }
                        searchTerm={searchTerm}
                        openObjectIcon={
                            <Icons.Open data-tooltip={t('openForm')} />
                        }
                        locateIcon={
                            <Icons.Locate2
                                data-tooltip={t('locateAndHighlight')}
                            />
                        }
                        onLocateAction={() => {
                            const center = locateCenterObject(item.location);
                            if (center) {
                                mapController.pan(center);
                                setIsHighlighted(true);
                                setHighlightPositions([
                                    {
                                        id: item.id?.toString() ?? '',
                                        position: center,
                                    },
                                ]);
                            }
                        }}
                        onOpenObjectAction={() =>
                            showObject(item.model_name!, item.id!)
                        }
                        data-testid={`full-search-result-${index}`}
                    />
                ))}
            </FullSearchResultContainer>
        ) : (
            Object.entries(groupByModelName).map(([key, value]) =>
                selectedObjectType === '' ||
                key.includes(selectedObjectType.slice(0, -6))
                    ? renderSearchResultGroup(value, key, rightBtnLabel)
                    : null,
            )
        );
    };

    const renderSearchResultGroup = (
        items: SearchResult[],
        titleKey: string,
        rightBtnLabel: string,
    ) => (
        <FullSearchResultContainer
            title={modelNames[titleKey as ModelName]}
            key={titleKey}
            rightBtnLabel={rightBtnLabel}
            data-testid={`full-search-group-${titleKey}`}
            rightBtnAction={() => handleHighlight(items)}
        >
            {items.map((item, index) => (
                <FullSearchResultCard
                    key={item.id}
                    objectIcon={getModelIcon(item.model_name as IconModel)}
                    name={item.identification as string}
                    type={
                        item.model_name
                            ? getTranslatedTitle(
                                  getTypeByModel(item.model_name),
                              )
                            : t('unknownObjectType')
                    }
                    searchTerm={searchTerm}
                    openObjectIcon={<Icons.Open data-tooltip={t('openForm')} />}
                    locateIcon={
                        <Icons.Locate2 data-tooltip={t('locateAndHighlight')} />
                    }
                    onLocateAction={() => {
                        const center = locateCenterObject(item.location);
                        if (center) {
                            mapController.pan(center);
                            setIsHighlighted(true);
                            setHighlightPositions([
                                {
                                    id: item.id?.toString() ?? '',
                                    position: center,
                                },
                            ]);
                        }
                    }}
                    onOpenObjectAction={() =>
                        showObject(item.model_name!, item.id!)
                    }
                    data-testid={`full-search-result-${index}`}
                />
            ))}
        </FullSearchResultContainer>
    );

    const rightBtnLabel = selectedObjectType
        ? t('highlightAll')
        : t('showMore');

    return (
        <StyledContainer ref={ref} data-testid="full-search">
            {isAdvancedSearchOpen && (
                <StyledOverlay
                    ref={refOverlay}
                    onClick={() => (refOverlay.current!.style.display = 'none')}
                    data-testid="full-search-overlay"
                />
            )}
            {isHighlighted &&
                highlightPositions.map((item) => (
                    <PositionOverlay
                        key={item.id}
                        position={item.position as Coordinates}
                        positioning="bottom-center"
                        safeElement={true}
                    >
                        <Icons.LocationMark style={{ width: 40, height: 40 }} />
                    </PositionOverlay>
                ))}
            <StyledSearchContainer>
                <StyledSearchFilters>
                    <StyledSearchFiltersTypes>
                        {renderFilters()}
                    </StyledSearchFiltersTypes>
                </StyledSearchFilters>
                <StyledDivider />
                <StyledSearchResults data-testid="full-search-results">
                    {renderSearchResults()}
                </StyledSearchResults>
                <AdvancedSearch
                    ref={advancedSearchRef}
                    isOpen={isAdvancedSearchOpen}
                    handleClose={() => setIsAdvancedSearchOpen(false)}
                    selectedAdvancedFilters={Object.fromEntries(
                        Object.entries(selectedAdvancedFilters).filter(
                            ([key]) => key !== 'id',
                        ),
                    )}
                    setSelectedAdvancedFilters={setSelectedAdvancedFilters}
                    setModelAdvancedSearch={setModelAdvancedSearch}
                    setIsUsingAdvancedSearch={setIsUsingAdvancedSearch}
                    setDataAdvancedSearch={setDataAdvancedSearch}
                    onLoading={setIsLoading}
                    searchFromRecent={isRecentObjects}
                    data-testid="advanced-search"
                />
            </StyledSearchContainer>
        </StyledContainer>
    );
};

// Styled components
const StyledContainer = styled.div`
    height: 100%;
`;

const StyledOverlay = styled.div`
    position: fixed;
    width: 348px;
    height: calc(100vh - 110px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const StyledSearchContainer = styled.div`
    background-color: ${(props) => props.theme.colors.neutral['20']};
    height: 100%;
`;

const StyledSearchFilters = styled.div``;

const StyledSearchFiltersTypes = styled.div`
    padding-top: 16px;
    padding-bottom: 8px;
`;

const StyledButtons = styled.div`
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    & > button {
        border-radius: 28px;
        padding: 6px 10px;
        margin: 4px;
        background-color: ${(props) => props.theme.colors.neutral['50']};
    }
`;

const StyledDivider = styled.div`
    height: 2px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.neutral['30']};
`;

const StyledSearchFiltersOptions = styled.div`
    padding: 8px 16px;
`;

const StyledButtonAddFilter = styled(Button)`
    ${(props) => props.theme.fonts['12px Medium']};
    background-color: ${(props) => props.theme.colors.neutral['50']};
    height: 24px;
    margin-right: 8px;
`;

const StyledSearchResults = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: calc(100vh - 270px);
    overflow-y: auto;
`;

const StyledSearchFiltersType = styled(Button)<{ $isSelected: boolean }>`
    ${(props) => props.theme.fonts['12px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
    background-color: ${(props) =>
        props.$isSelected
            ? props.theme.colors.accents.blue['10']
            : props.theme.colors.neutral['50']} !important;
`;

const StyledAdvancedSearchFilters = styled.div`
    margin-top: -2px;
    display: inline;
`;

const StyledChip = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    background-color: ${(props) => props.theme.colors.neutral['40']};
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 4px;
    padding: 4px 4px 4px 6px;
    color: ${(props) => props.theme.colors.neutral['90']};
    cursor: pointer;
    margin: 2px;
`;

const StyledChipClear = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.neutral['80']};
    & > svg {
        width: 14px;
        height: 14px;
    }
`;

const StyledLoadingAndNoResults = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
`;
