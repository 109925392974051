import { t } from 'i18next';
import { toCamelCase } from '@components/FormBuilder';
import { FormConfig, FormConfigs, FormData } from './types';

// KeyCore
import Address from './keycore/Address';
import Apartment from './keycore/Apartment';
import Plan from './keycore/Plan';
import TxtConstant from './keycore/TxtConstant';
import FreeLine from './keycore/FreeLine';
import FreeArea from './keycore/FreeArea';
import Document from './keycore/Document';
import Point from './keycore/Point';

// KeyCom
import Manhole from './keycom/Manhole';
import ManholeSearch from './search-form/Manhole';
import TelecomArea from './keycom/TelecomArea';
import ExchangeArea from './keycom/ExchangeArea';
import ServiceArea from './keycom/ServiceArea';
import Pole from './keycom/Pole';
import Cable from './keycom/Cable';
import Conduit from './keycom/Conduit';
import Splice from './keycom/Splice';
import TelecomPremise from './keycom/TelecomPremise';

/**
 * All available form configurations.
 */
const formConfigs = [
    Address,
    Apartment,
    Plan,
    TxtConstant,
    FreeLine,
    FreeArea,
    Document,
    Point,
    Manhole,
    ManholeSearch,
    TelecomArea,
    ExchangeArea,
    ServiceArea,
    Pole,
    Cable,
    Conduit,
    Splice,
    TelecomPremise,
].reduce((acc, config) => {
    acc[config.gqlType] = config;
    return acc;
}, {} as FormConfigs);

/**
 * Get the form config for a given GraphQL type.
 * @param gqlType The GraphQL type to get the form config for.
 * @returns The form config for the given GraphQL type.
 */
export const getFormConfig = (gqlType: string): FormConfig => {
    if (formConfigs[gqlType]) {
        return formConfigs[gqlType];
    }
    throw new Error(`Missing form config for type: ${gqlType}`);
};

/**
 * Get the field config for a given field name.
 * @param form The form config to get the field from.
 * @param fieldName The field name to get the config for.
 * @returns The field config for the given model name or null if not found.
 */
export const getFormField = (form: FormConfig, fieldName: string) => {
    for (const group of form.groups) {
        for (const field of group.fields) {
            if (typeof field === 'string') {
                if (field === fieldName) {
                    return { name: field };
                }
            } else if (field.name === fieldName) {
                return field;
            }
        }
    }

    return null;
};

/**
 * Get the GraphQL type name for a given model name.
 * @param model The model name.
 * @returns The GraphQL type name for the given model name.
 */
export const getTypeByModel = (model: string): string => {
    for (const key in formConfigs) {
        if (
            formConfigs[key].model === model ||
            formConfigs[key].modelAliases?.includes(model)
        ) {
            return key;
        }
    }
    throw new Error(`Missing type for model: ${model}`);
};

/**
 * Check if a given model is supported.
 * @param model The model name to check.
 * @returns True if the model is supported.
 */
export const isModelSupported = (model: string): boolean => {
    try {
        const type = getTypeByModel(model);
        return hasFormConfig(type);
    } catch (e) {
        return false;
    }
};

/**
 * Check if a form config exists for a given GraphQL type.
 * @param gqlType The GraphQL type to check for a form config.
 * @returns True if a form config exists for the given GraphQL type.
 */
export const hasFormConfig = (gqlType: string): boolean => {
    return !!formConfigs[gqlType];
};

/**
 * Gets field names from all form configs prefixed with form name.
 */
export const getTranslationKeys = (): string[] => {
    const keys = [] as string[];

    for (const form in formConfigs) {
        console.log(`Generating locales for ${form}`);
        keys.push(`${form}.$title`);
        keys.push(`${form}.$titlePlural`);

        formConfigs[form].groups.forEach((group) => {
            if (group.translationKey) {
                keys.push(group.translationKey);
            } else {
                keys.push(group.name);
            }

            group.fields.forEach((field) => {
                if (typeof field !== 'string' && field.translationKey) {
                    keys.push(field.translationKey);
                } else {
                    const fieldName =
                        typeof field === 'string' ? field : field.name;
                    keys.push(toCamelCase(fieldName));
                }
            });
        });
    }
    return keys;
};

/**
 * Get the translated form title for a given GraphQL type.
 * @param gqlType The GraphQL type to get the translated title for.
 * @returns The translated title for the given GraphQL type.
 */
export const getTranslatedTitle = (gqlType: string): string => {
    // Put translation key into a variable to prevent i18next-parser from gathering it
    const key = `${gqlType}.$title`;
    return t(key);
};

/**
 * Get the translated form title in plural form for a given GraphQL type.
 * @param gqlType The GraphQL type to get the translated title for.
 * @returns The translated plural title for the given GraphQL type.
 */
export const getTranslatedPluralTitle = (gqlType: string): string => {
    // Put translation key into a variable to prevent i18next-parser from gathering it
    const key = `${gqlType}.$titlePlural`;
    return t(key);
};

/**
 * Get the translation key for a given field name.
 * @param gqlType The GraphQL type to get the translation key for.
 * @param fieldName The field name to get the translation key for.
 * @returns The translation key for the given field name.
 */
export const getTranslationKey = (gqlType: string, fieldName: string) => {
    const form = getFormConfig(gqlType);
    const fieldConfig = getFormField(form, fieldName);

    return fieldConfig?.translationKey
        ? fieldConfig.translationKey
        : toCamelCase(fieldName);
};

/**
 * Check if a given value is empty.
 * @param value The value to check.
 * @returns True if the value is empty.
 */
const isEmpty = (value: unknown) => {
    return value === null || value === undefined || value === '';
};

/**
 * Generates a human-readable label based on the given object type and object data.
 * If label processor is not defined in the form configuration or it doesn't return
 * a non-empty string, "identification" or "name" field is used. If neither of
 * those fields are present or they are null, the label will be "ModelName ID".
 * @param gqlType The GraphQL type to get the label for.
 * @param data The object data.
 * @returns The label for the given model and data.
 */
export const getLabel = (gqlType: string, data: FormData) => {
    const form = getFormConfig(gqlType);
    let label = null;

    if (form.labelFormatter) {
        label = form.labelFormatter(data);
    }

    if (isEmpty(label)) {
        label = data.identification ?? data.name;
    }

    if (isEmpty(label)) {
        label = `${getTranslatedTitle(gqlType)} ${data.id ?? data.pk ?? data.mslink}`;
    }

    return label as string;
};
