import { useSearch } from '@hooks/keycore/search';
import { InfoObject } from '@components';
import { getModelIcon, handleOutsideClick, IconModel } from '@components/utils';
import { Maybe, SearchResult } from '@generated';
import {
    Button,
    Icons,
    Input,
    LoadingMask,
    MapContext,
    PositionOverlay,
    QuickSearchResultCard,
    QuickSearchResultContainer,
    styled,
    Coordinates,
} from '@keypro/2nd-xp';
import { useLeftMenu, useRecentObjects, useRightMenu } from '@stores';
import { t } from 'i18next';
import {
    ChangeEvent,
    HTMLAttributes,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { FullSearch } from './FullSearch';
import { getLabel, getTranslatedTitle, getTypeByModel } from '@form-configs';

/**
 * Delay in milliseconds before search is performed after user has stopped typing.
 * This delay helps to reduce the number of search requests when user is typing.
 */
const SEARCH_DELAY = 250;

/**
 * Initial number of objects shown below the search field.
 */
const INITIAL_OBJECT_COUNT = 10;

const SearchInput = styled(Input)`
    background-color: ${(props) => props.theme.colors.neutral[20]};
    border: 0;
    width: 332px;

    &:focus,
    &:focus-within {
        box-shadow: none;
    }

    &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

const SearchContainer = styled.div`
    position: relative;
`;

const QuickSearchResults = styled.div`
    position: absolute;
    top: 100%;

    z-index: 100;
    width: 100%;

    & > div,
    & > div > div {
        border-radius: 0 0 8px 8px;
        background-color: ${(props) => props.theme.colors.neutral[20]};
    }

    & > div {
        border-top: 1px solid ${(props) => props.theme.colors.neutral[30]};
    }
`;

const ShowMoreResults = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.neutral[30]};

    display: flex;
    justify-content: center;
    padding: 12px 12px 8px 12px;
    margin-left: 8px;

    button {
        border: 0;
        background-color: transparent;
        color: ${(props) => props.theme.colors.accents.blue[10]};
        ${(props) => props.theme.fonts['14px Regular']};
        cursor: pointer;
    }
`;

const SearchButton = styled(Button)`
    width: 24px;
    height: 24px;

    svg {
        width: 16px;
        height: 16px;

        path {
            fill: ${(props) => props.theme.colors.neutral[80]};
            stroke: ${(props) => props.theme.colors.neutral[80]};
        }
    }
`;

const CloseButton = styled(Button)`
    background-color: ${(props) => props.theme.colors.neutral[50]};
    width: 20px;
    height: 20px;
    border-radius: 50%;

    svg {
        width: 9px;
        height: 9px;

        path {
            stroke-width: 4px;
            color: ${(props) => props.theme.colors.neutral[90]};
        }
    }

    &:hover {
        background-color: ${(props) =>
            props.theme.colors.neutral[60]} !important;
    }
`;

const EndButtons = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

/**
 * Combined search field for both quick and full search.
 */
const SearchField = (props: HTMLAttributes<HTMLDivElement>) => {
    const [displayValue, setDisplayValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [timeoutId, setTimeoutId] = useState<number | null>(null);
    const [active, setActive] = useState(false);
    const mapController = useContext(MapContext)!;
    const { setMenuContent: setRightMenuContent } = useRightMenu();
    const [highlightPosition, setHighlightPosition] = useState<number[]>([
        0, 0,
    ]);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const {
        setMenuContent: setLeftMenuContent,
        isMenuOpen: resultsOpen,
        toggleMenu: toggleLeftMenu,
    } = useLeftMenu();
    const { recentObjects } = useRecentObjects();

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDisplayValue(event.target.value);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = window.setTimeout(() => {
            setSearchValue(event.target.value);
        }, SEARCH_DELAY);

        setTimeoutId(newTimeoutId);
    };

    const onFocus = () => {
        setActive(true);
    };

    const showFullSearch = () => {
        setActive(false);
        setLeftMenuContent(
            `FullSearch-${searchValue}`,
            <FullSearch searchResults={results} searchTerm={searchValue} />,
        );
    };

    const showRecentObjects = () => {
        setActive(false);
        setLeftMenuContent(
            'FullSearch',
            <FullSearch searchResults={recentObjects} isRecentObjects={true} />,
        );
    };

    const { data: results, isFetching: searching } = useSearch(searchValue);

    const resultsForQuickSearch = useMemo(() => {
        if (searchValue && results) {
            return results.slice(0, INITIAL_OBJECT_COUNT);
        }

        return [];
    }, [searchValue, results]);

    const endButtons: JSX.Element[] = [
        <SearchButton
            key="doSearch"
            kind="ghost"
            onClick={showFullSearch}
            data-tooltip={t('openFullSearch')}
            data-testid="open-full-search-button"
        >
            <Icons.Search />
        </SearchButton>,
    ];

    const closeSearch = () => {
        // Reactivate search field on the next animation frame after closing results
        requestAnimationFrame(() =>
            ref.current?.querySelector('input')?.focus(),
        );

        if (resultsOpen) {
            toggleLeftMenu();
        }

        setActive(false);
        setDisplayValue('');
        setSearchValue('');
        setIsHighlighted(false);
    };

    if (searchValue || resultsOpen) {
        endButtons.push(
            <CloseButton
                key="clearSearch"
                kind="ghost"
                data-tooltip={t('closeSearch')}
                onClick={closeSearch}
                data-testid="close-search-button"
            >
                <Icons.Cross2 />
            </CloseButton>,
        );
    }

    // Hide results when clicking outside of search field or results component
    useEffect(() => {
        return handleOutsideClick(ref, setActive, active);
    }, [ref, active]);

    /**
     * Shows the object in the right menu.
     * @param model Model of the object.
     * @param id ID of the object.
     */
    const showObject = (model: string, id: number) => {
        setRightMenuContent(
            `QuickSearchObject-${model}-${id}`,
            <InfoObject model={model} id={id.toString()} />,
        );
    };

    /**
     * Locates and highlights the object on the map.
     * @param location Location of the object.
     */
    const locate = (location: Maybe<string> | undefined) => {
        if (!location) return;

        const locationParts = location.split(';');
        const geom = mapController.wktToGeometry(
            locationParts[locationParts.length - 1],
        );

        if (geom) {
            const extent = geom.getExtent();
            const center = [
                (extent[0] + extent[2]) / 2,
                (extent[1] + extent[3]) / 2,
            ];
            mapController.pan(center);
            setIsHighlighted(true);
            setHighlightPosition(center);
        }
    };

    /**
     * Creates a search result card for the given search result.
     * @param result Search result.
     * @param index Index of the search result.
     * @returns Search result card.
     */
    const createSearchResultCard = (result: SearchResult, index: number) => {
        let name = result.identification ?? '';
        let info = t('unknownObjectType');

        if (result.model_name) {
            const gqlType = getTypeByModel(result.model_name);
            name = getLabel(gqlType, result);
            info = getTranslatedTitle(gqlType);
        }

        return (
            <QuickSearchResultCard
                key={result.id}
                name={name}
                info={info}
                objectIcon={getModelIcon(result.model_name as IconModel)}
                openObjectIcon={<Icons.Open data-tooltip={t('openForm')} />}
                locateIcon={
                    <Icons.Locate2 data-tooltip={t('locateAndHighlight')} />
                }
                searchTerm={searchValue}
                onLocateAction={() => locate(result.location)}
                onOpenObjectAction={() =>
                    showObject(result.model_name!, result.id!)
                }
                data-testid={`search-result-${index}`}
            />
        );
    };

    let content: JSX.Element;

    if (searching) {
        content = (
            <QuickSearchResultContainer>
                <LoadingMask iconSize={24} />
            </QuickSearchResultContainer>
        );
    } else if (searchValue) {
        if (resultsForQuickSearch?.length) {
            content = (
                <QuickSearchResultContainer
                    title={t('keycomSuggestions')}
                    data-testid="search-results"
                >
                    {resultsForQuickSearch.map(createSearchResultCard)}
                    <ShowMoreResults>
                        <button
                            onClick={showFullSearch}
                            data-testid="show-more-results"
                        >
                            {t('showMoreResults')}
                        </button>
                    </ShowMoreResults>
                </QuickSearchResultContainer>
            );
        } else {
            content = (
                <QuickSearchResultContainer
                    title={t('noSearchResults')}
                ></QuickSearchResultContainer>
            );
        }
    } else if (recentObjects.length) {
        content = (
            <QuickSearchResultContainer
                title={t('recentlyViewed')}
                data-testid="recent-objects"
            >
                {recentObjects
                    .slice(0, INITIAL_OBJECT_COUNT)
                    .map(createSearchResultCard)}
                <ShowMoreResults>
                    <button
                        onClick={showRecentObjects}
                        data-testid="show-more-history"
                    >
                        {t('moreFromRecentHistory')}
                    </button>
                </ShowMoreResults>
            </QuickSearchResultContainer>
        );
    } else {
        content = (
            <QuickSearchResultContainer
                title={t('noRecentObjects')}
            ></QuickSearchResultContainer>
        );
    }

    return (
        <SearchContainer ref={ref} {...props}>
            <PositionOverlay
                position={highlightPosition as Coordinates}
                positioning="bottom-center"
                style={{
                    display: isHighlighted ? 'block' : 'none',
                }}
            >
                <Icons.LocationMark style={{ width: 40, height: 40 }} />
            </PositionOverlay>
            <SearchInput
                inputProps={{
                    value: displayValue,
                    placeholder: t('searchKeycom'),
                    onChange: onChange,
                    onFocus: onFocus,
                    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                            showFullSearch();
                        }
                    },
                }}
                iconEnd={<EndButtons>{endButtons}</EndButtons>}
                className={active ? 'active' : ''}
            />
            {active && <QuickSearchResults>{content}</QuickSearchResults>}
        </SearchContainer>
    );
};

export default SearchField;
